<template>
  <v-row justify="space-around" no-gutters>
    <v-col sm="12" md="10" lg="9" xl="8">
      <v-card class="pa-0 ma-0">
        <v-container class="pa-0 ma-0">
          <v-row no-gutters justify="space-around" class="hidden-lg-and-up">
            <v-col>
              <h3 style="text-align: center;color: #f7a600;">{{ section_meeting.shortTitle }}</h3>
              <hr color="#f7a600">
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="12" lg="5" xl="4" class="ma-0 pa-0">
              <vue-iframe v-if="section_meeting.mapsLink" :src="section_meeting.mapsLink" style="width: 100%; height: 400px"/>
              <hr color="#f7a600" class="hidden-lg-and-up">
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="7" xl="8">
              <v-row no-gutters justify="space-around" class="hidden-md-and-down">
                <v-col>
                  <h3 style="text-align: center;color: #f7a600;">{{ section_meeting.title }}</h3>
                  <hr color="#f7a600">
                </v-col>
              </v-row>
              <v-row no-gutters justify="space-around" class="pl-5 pr-5 pt-5">
                <v-col>
                  <div v-html="section_meeting.text"></div>
                </v-col>
              </v-row>
              <v-row no-gutters justify="space-around" class="pl-5 pr-5 ">
                <v-col>
                  <h4>WANN</h4>
                  <div v-html="section_meeting.wann"></div>
                </v-col>
              </v-row>
              <v-row no-gutters justify="space-around" class="pa-5">
                <v-col>
                  <h4>WO</h4>
                  <div v-html="section_meeting.wo"></div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'section-meeting',
  props: {
    section_meeting: {}
  }
}
</script>
<style>
.content p {
  font-size: 16px;
  line-height: 1.5em;
  font-family: 'Roboto', 'Calibri', sans-serif;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin: 50px 0 10px;
}

.content p strong {
  color: black;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 24px;
  font-weight: 500;
}

ol li, ul li {
  font-size: 16px;
  line-height: 22px;
  color: black;
}

.content h1 {
  text-transform: uppercase;
  font-size: 3em;
  margin: 0.67em 0;
}

.content h1, h2, h3 {
  font-family: 'Lato', 'Roboto', 'Calibri', sans-serif;
  font-weight: 700;
  /*color: #f7a600;*/
  color: black;
}

.content h2, h3 {
  font-weight: 600;
  font-size: 1.8em;
}

</style>
<style scoped>

h3 {
  font-size: 1.3em;
}

h6 {
  color: var(--v-primary-base);
  font-size: 0.625em;
  padding-top: 8px;
}

@media screen and (min-width: 1905px) {
}

@media screen and (min-width: 1441px) and (max-width: 1904px) {
}

@media screen and (min-width: 1265px) and (max-width: 1440px) {
}

@media screen and (min-width: 961px) and (max-width: 1264px) {
}

@media screen and (min-width: 601px) and (max-width: 960px) {
}

@media screen and (max-width: 600px) {
}

</style>