const locale = 'de-DE';

export const formatCurrency = function (number) {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
    }).format(number);
};

export const formatPercentage = function (number) {
    if (!number) {
        number = 0;
    }
    return new Intl.NumberFormat('de-DE', {
        style: 'percent',
        minimumFractionDigits: 0,
    }).format(number);
}