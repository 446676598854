<template>
  <v-card :elevation="hover ? 12 : 2" min-width="200px" min-height="200px" :key="project.path"
          :to="'/iog_project/' + project.path">
    <v-img
        :src="project.header_image.data.full_url"
        height="200px"
    >
    </v-img>
    <v-card-title :class="[ project.project_state === 'Abgeschlossen' ? 'done' : 'progress' ]">
      <div class="title">
        <h2 v-html="project.name" class="project"></h2>
        <p class="country">{{ project.country }}</p>
      </div>
    </v-card-title>
    <v-card-subtitle :class="[ project.project_state === 'Abgeschlossen' ? 'done' : 'progress' ]">
      <div class="subtitle">
        <p class="description">{{ project.title }}</p>
      </div>
      <div class="footer">
        <p v-if="project.project_state === 'Abgeschlossen'" class="thanks">Danke an alle
          Unterstützer:innen!</p>
        <div v-else align="center" class="thanks">
          <span class="financeValues">{{ getCurrency(project.finance_value) }} |</span>
          <span class="percentageValue"> {{ getPercentage(project) }}  </span>
          <span class="financeValues">| von {{ getCurrency(project.finance_target) }}</span>
          <v-progress-linear :value="(project.finance_value / project.finance_target) * 100.0"
                             color="white"></v-progress-linear>
        </div>
      </div>
    </v-card-subtitle>
  </v-card>
</template>
<script>
import {formatCurrency, formatPercentage} from "@/utils/utils";

export default {
  name: 'project-card',
  props: {
    hover: {},
    project: {}
  },
  methods: {
    getCurrency(number) {
      return formatCurrency(number);
    },
    getPercentage(project) {
      return formatPercentage(project.finance_value / project.finance_target);
    },
  },
}
</script>
<style scoped>

.project {
  font-family: 'Lato', 'Roboto', 'Calibri', sans-serif;
  text-transform: uppercase;
  display: inline-block;
  padding: 4px 0;
  margin: 0;
  color: white;
  text-align: left;
  letter-spacing: 0.8px;
  font-weight: 600;
  font-size: 1.5em;
  line-height: 1.1;
}

.country {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 1em;
  line-height: 1.5em;
  color: white;
  margin: 0 0 10px;
}

.description {
  letter-spacing: 2px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: black;
  margin: 0 0 10px;
}

.financeValues {
  color: black;
}

.percentageValue {
  color: white;
}

.thanks {
  color: white;
  font-size: 1em;
  font-weight: bold;
  letter-spacing: 0.6px;
}

.title {
  min-height: 4em;
}

.title h2 {
  font-family: 'Lato', 'Roboto', 'Calibri', sans-serif;
  text-transform: uppercase;
  display: inline-block;
  padding: 4px 0;
  margin: 0;
  color: white;
  text-align: left;
  letter-spacing: 0.8px;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.1;
}

.subtitle {
  min-height: 5em;
}

.footer {
  min-height: 3em;
}


.done {
  background-color: var(--v-secondary-base);
}

.progress {
  background-color: var(--v-primary-base);
}

</style>