<template>
  <div class="mainContent">
    <img id="page-header-image" v-if="header_image" :src="header_image.data.full_url" :title="header_image.title"
         class="headerImage"
         lazy-src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABLAAAAAyCAYAAABMFiIPAAABcklEQVR42u3YQQEAMAgAIU2+6K7GPSAGezNvAAAAACBqBRYAAAAAZQILAAAAgDSBBQAAAECawAIAAAAgTWABAAAAkCawAAAAAEgTWAAAAACkCSwAAAAA0gQWAAAAAGkCCwAAAIA0gQUAAABAmsACAAAAIE1gAQAAAJAmsAAAAABIE1gAAAAApAksAAAAANIEFgAAAABpAgsAAACANIEFAAAAQJrAAgAAACBNYAEAAACQJrAAAAAASBNYAAAAAKQJLAAAAADSBBYAAAAAaQILAAAAgDSBBQAAAECawAIAAAAgTWABAAAAkCawAAAAAEgTWAAAAACkCSwAAAAA0gQWAAAAAGkCCwAAAIA0gQUAAABAmsACAAAAIE1gAQAAAJAmsAAAAABIE1gAAAAApAksAAAAANIEFgAAAABpAgsAAACANIEFAAAAQJrAAgAAACBNYAEAAACQJrAAAAAASBNYAAAAAKQJLAAAAADSBBYAAAAAaR+2qUrPM1FL2wAAAABJRU5ErkJggg==">
    <v-container v-if="loading" fill-height fluid>
      <v-row align="center">
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
      </v-row>
    </v-container>
    <v-container v-if="error" class="mt-2" fill-height fluid>
      <v-row align="center">
        <v-col>
          <div class="error">{{ error }}</div>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="section" class="mt-2">
      <v-row justify="space-around">
        <v-col sm="12" md="10" lg="9" xl="8">
          <v-tabs v-model="tab">
            <v-tab v-for="tabItem in tabNames" :key="tabItem"
                   :to="getPath(tabItem)">
              {{ tabItem }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row justify="space-around">
        <v-col sm="12" md="10" lg="9" xl="8">
          <div v-html="section.description" class="content"></div>
        </v-col>
      </v-row>
      <v-row justify="space-around">
        <v-col sm="12" md="10" lg="9" xl="8">
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <div v-html="section.team_text" class="content"></div>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6" align-self="center">
              <img id="map-image" v-if="section.team_image" :src="section.team_image.data.full_url"
                   :title="section.team_image.title"
                   class="contentImage pa-2">
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <br>
      <v-container v-if="section.rg_treff_exists" class="mt-2">
        <section-meeting :section_meeting="rg_treffen"/>
      </v-container>
      <v-container v-if="section.stammtisch_exists" class="mt-2">
        <section-meeting :section_meeting="stammtisch"/>
      </v-container>
      <v-row>
        <v-col col="12" class="mb-10">
          <h3 align="center">Projekte</h3>
        </v-col>
      </v-row>
      <v-row justify="space-around">
        <v-col v-for="project in projects" :key="project.name" sm="12" md="6" lg="5" xl="4">
          <v-hover v-slot="{ hover }">
            <project-card :hover="hover" :project="project"/>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import client from '../directus';
import SectionMeeting from "@/components/SectionMeeting";
import ProjectCard from "@/components/ProjectCard";

export default {
  name: 'Section',
  components: {SectionMeeting, ProjectCard},
  data() {
    return {
      tab: null,
      loading: false,
      section: null,
      projects: [],
      sectionName: null,
      error: null,
      header_image: null,
      rg_treffen: null,
      stammtisch: null,
      tabNames: ["Graz", "Leoben", "Tirol", "Wien"],
    };
  },
  computed: {
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.loadSection();
  },
  watch: {
    // call again the method if the route changes
    '$route': 'loadSection',
  },
  methods: {
    getPath(section) {
      console.log(section);
      return "/iog_section/" + section;
    },
    async getSection(name) {
      try {
        let result = await client.getItems('section', {
          fields: '*, header_image.*.*, team_image.*.*',
          single: true,
          filter: {
            name: {
              eq: name,
            },
          },
        });
        if (!result) {
          return null;
        }
        return result.data;
      } catch (e) {
        console.error(e);
      }
    },
    async getProjects(section) {
      try {
        let result = await client.getItems('project', {
          fields: '*, header_image.*.*, section.*.*',
          sort: '-project_state, name',
          filter: {
            section: {
              eq: section.id
            }
          },
        });
        if (!result) {
          return null;
        }
        return result.data;
      } catch (e) {
        console.error(e);
      }
    },
    async goToSection(section) {
      this.tab = this.tabNames.indexOf(section);
      this.sectionName = section;
      this.error = this.section = null;
      this.loading = true;
      try {
        const section = await this.getSection(this.sectionName);
        this.projects = await this.getProjects(section);
        if (section) {
          this.header_image = section.header_image;
          if (section.rg_treff_exists) {
            this.rg_treffen = {
              title: "Regionalgruppentreffen",
              shortTitle: "RG Treffen",
              text: section.rg_treff_text,
              wann: section.rg_treff_wann,
              wo: section.rg_treff_wo,
              mapsLink: section.rg_treff_maps_link,
            };
          }
          if (section.stammtisch_exists) {
            this.stammtisch = {
              title: "Stammtisch",
              shortTitle: "Stammtisch",
              text: section.stammtisch_text,
              wann: section.stammtisch_wann,
              wo: section.stammtisch_wo,
              mapsLink: section.stammtisch_maps_link,
            };
            this.section = section;
          }
        } else {
          this.section = null;
          this.rg_treffen = null;
          this.stammtisch = null;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.error = error;
        console.error('There was an error!', error);
      }
    },
    loadSection() {
      this.goToSection(this.$route.params.name);
    },
  },
};
</script>

<style>
.content p {
  font-size: 16px;
  line-height: 1.5em;
  font-family: 'Roboto', 'Calibri', sans-serif;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin: 50px 0 10px;
}

.content p strong {
  color: black;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 24px;
  font-weight: 500;
}

ol li, ul li {
  font-size: 16px;
  line-height: 22px;
  color: black;
}

.content h1 {
  text-transform: uppercase;
  font-size: 3em;
  margin: 0.67em 0;
}

.content h1, h2, h3 {
  font-family: 'Lato', 'Roboto', 'Calibri', sans-serif;
  font-weight: 700;
  /*color: #f7a600;*/
  color: black;
}

.content h2, h3 {
  font-weight: 600;
  font-size: 1.8em;
}

.content .h1, .h2, .h3, h1, h2, h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

</style>

<style scoped>
.mainContent {
  min-height: 100%;
  max-width: 100%;
}

.headerImage {
  display: block;
  max-width: 100%;
  height: auto;
  width: 100%;
  vertical-align: bottom;
  border: 0;
}

.contentImage {
  display: block;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  border: 0;
}

h3 {
  font-size: 1.3em;
}

h6 {
  color: var(--v-primary-base);
  font-size: 0.625em;
  padding-top: 8px;
}

@media screen and (min-width: 1905px) {
  .project-gallery {
    height: 800px !important;
    width: 100%;
  }
}

@media screen and (min-width: 1441px) and (max-width: 1904px) {
  .project-gallery {
    height: 600px !important;
    width: 100%;
  }
}

@media screen and (min-width: 1265px) and (max-width: 1440px) {
  .project-gallery {
    height: 500px !important;
    width: 100%;
  }
}

@media screen and (min-width: 961px) and (max-width: 1264px) {
  .project-gallery {
    height: 400px !important;
    width: 100%;
  }
}

@media screen and (min-width: 601px) and (max-width: 960px) {
  .project-gallery {
    height: 300px !important;
    width: 90vw;
  }
}

@media screen and (max-width: 600px) {
  .project-gallery {
    height: 300px !important;
    width: 90vw;
  }
}

</style>