<template>
  <div style="min-height: 100%">
    <img id="page-header-image" v-if="header_image" :src="header_image.data.full_url" :title="header_image.title"
         class="headerImage"
         lazy-src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABLAAAAAyCAYAAABMFiIPAAABcklEQVR42u3YQQEAMAgAIU2+6K7GPSAGezNvAAAAACBqBRYAAAAAZQILAAAAgDSBBQAAAECawAIAAAAgTWABAAAAkCawAAAAAEgTWAAAAACkCSwAAAAA0gQWAAAAAGkCCwAAAIA0gQUAAABAmsACAAAAIE1gAQAAAJAmsAAAAABIE1gAAAAApAksAAAAANIEFgAAAABpAgsAAACANIEFAAAAQJrAAgAAACBNYAEAAACQJrAAAAAASBNYAAAAAKQJLAAAAADSBBYAAAAAaQILAAAAgDSBBQAAAECawAIAAAAgTWABAAAAkCawAAAAAEgTWAAAAACkCSwAAAAA0gQWAAAAAGkCCwAAAIA0gQUAAABAmsACAAAAIE1gAQAAAJAmsAAAAABIE1gAAAAApAksAAAAANIEFgAAAABpAgsAAACANIEFAAAAQJrAAgAAACBNYAEAAACQJrAAAAAASBNYAAAAAKQJLAAAAADSBBYAAAAAaR+2qUrPM1FL2wAAAABJRU5ErkJggg==">
    <v-container v-if="loading" fill-height fluid>
      <v-row align="center" justify="center">
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
      </v-row>
    </v-container>
    <v-container v-if="error" class="mt-2" fill-height fluid>
      <v-row align="center" justify="center">
        <v-col>
          <div class="error">{{ error }}</div>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="page" class="mt-2">
      <v-row no-gutters>
        <v-col sm="1" md="3" lg="4">
          <div v-html="page.content" class="mainContent"></div>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="mt-2" v-if="sections">
      <v-row>
        <v-col col="12" class="mb-10">
          <h1 align="center" class="show-on-desktop">{{ page.title }}</h1>
          <h1 align="center" class="show-on-mobile">Regional-</h1>
          <h1 align="center" class="show-on-mobile">Gruppen</h1>
        </v-col>
      </v-row>
      <v-row class="mb-10">
        <v-col
            v-for="section in sections"
            :key="section.name"
            sm="12" md="6" lg="3" xl="4"
        >
          <v-card min-width="200px" min-height="200px" :to="'/iog_section/' + section.name">
            <v-img
                :src="section.header_image.data.full_url"
                height="200px"
            >
            </v-img>
            <v-card-title>
              <h2 v-text="section.name"></h2>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import client from '../directus';

export default {
  name: 'Sections',
  props: {
    path: String,
  },
  data() {
    return {
      loading: false,
      page: null,
      error: null,
      header_image: null,
      sections: null,
    }
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.loadPage()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'loadPage'
  },
  methods: {
    async getPage(name) {
      try {
        let result = await client.getItems('page', {
          filter: {
            path: {
              eq: name
            }
          },
          fields: "*, header_image.*.*",
          single: true
        });
        if (!result) {
          return [];
        }
        return result.data;
      } catch (e) {
        console.error(e);
      }
    },
    async getSections() {
      try {
        let result = await client.getItems('section', {
          fields: "*, header_image.*.*, team_image.*.*",
          sort: 'name',
        });
        if (!result) {
          return null;
        }
        return result.data;
      } catch (e) {
        console.error(e);
      }
    },
    async loadPage() {
      this.error = this.page = null;
      this.loading = true;
      try {
        const page_data = await this.getPage(this.path);
        if (page_data === []) {
          this.page = null;
          this.loading = false;
          this.error = "Leider nichts gefunden";
          return Promise.reject(this.error);
        }
        this.page = page_data;
        this.header_image = page_data.header_image;
        const sections = await this.getSections();
        if (sections) {
          this.sections = sections;
        } else {
          this.sections = [];
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.error = error;
        console.error("There was an error!", error);
      }
    },
  }
}
</script>
<style scoped>
.mainContent {
  min-height: 100%;
  max-width: 100%;
}

.headerImage {
  display: block;
  max-width: 100%;
  height: auto;
  width: 100%;
  vertical-align: bottom;
  border: 0;
}

@media (min-width: 761px) {
  .show-on-mobile { display: none !important; }
}
@media (max-width: 760px) {
  .show-on-desktop { display: none !important; }
}
</style>